export default {
  name: "VendorsDetailsComponent",
  data() {
    return {
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      tabs: null,
      screenHeight: 0,
      model: null,
      texts: "",
      vendorName: "",
      vendorDescription: "",
      sCategoryId: "",
      aCategory: [],
      sSubCategoryId: "",
      aSubCategory: [],
      sSearch: "",
      vendorDetailArr: "",
      tableDataUsers: [],
      articleDetailArr: [],
      wareHouseDetailArr: [],
      documentDetailArr: [],
      isMobile: false,
      isTablet: false,
      isFixed: false,
      isCenter: false,
      imgWidthResponive: false,
      isMobileOrIsTablet: false,
      screenWidth: 0,
      bVerified: null,
      bActive: null,
      bBlockStatus: null,
      rating: 3.5,
      sLogoKey: "",
    };
  },
  beforeMount() {
    this.texts = FILE.vendorTexts[this.selectLanguage];
    this.$store.commit("setMenuSidebar", { active: false });
    if (this.iTab == 2) {
      this.tabs = 2;
      this.$store.commit("setTab", 0);
    }
    this.vendorDetail();
  },
  updated() {
    this.resizeDivName();
    this.resizeDivLocation();
    this.resizeDivPrice();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize", this.resizeDivName);
    this.resizeDivName();
    window.addEventListener("resize", this.resizeDivLocation);
    this.resizeDivLocation();
    window.addEventListener("resize", this.resizeDivPrice);
    this.resizeDivPrice();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.resizeDivName);
    window.removeEventListener("resize", this.resizeDivLocation);
    window.removeEventListener("resize", this.resizeDivPrice);
  },
  methods: {
    // muestra dialog para eliminar el proveedor
    deleteItemVendors(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.vendorDetails.profileDetail.deleteTexts.title,
        descipcion: this.texts.vendorDetails.profileDetail.deleteTexts
          .description,
        note: this.texts.vendorDetails.profileDetail.deleteTexts.note,
        chkLabel: this.texts.vendorDetails.profileDetail.deleteTexts.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${item.sVendorEnterpriseId}`,
        return: true,
        dobleConfirm: true,
      });
    },
    // muestra dialog para eliminar al usuario
    deleteItemUser(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.vendorDetails.usersDetail.deleteTexts.title,
        descipcion: this.texts.vendorDetails.usersDetail.deleteTexts
          .description,
        note: this.texts.vendorDetails.usersDetail.deleteTexts.note,
        chkLabel: this.texts.vendorDetails.usersDetail.deleteTexts.chkLabel,
        dobleConfirm: true,
        api: `${URI}/api/v1/${this.selectLanguage}/users/${item.sVendorId}`,
      });
    },
    // muestra dialog para eliminar el almacen
    deleteItemWareHouse(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.vendorDetails.warehousesDetail.delete.title,
        descipcion: this.texts.vendorDetails.warehousesDetail.delete
          .description,
        note: this.texts.vendorDetails.warehousesDetail.delete.note,
        chkLabel: this.texts.vendorDetails.warehousesDetail.delete.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${item.sVendorEnterpriseId}/warehouses/${item.sVendorWarehouseId}`,
        dobleConfirm: true,
      });
    },
    // muestra dialog para eliminar el documento
    deleteItemDocument(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.vendorDetails.documentsDetails.delete.title,
        descipcion: this.texts.vendorDetails.documentsDetails.delete
          .description,
        note: this.texts.vendorDetails.documentsDetails.delete.note,
        chkLabel: this.texts.vendorDetails.documentsDetails.delete.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${item.sVendorEnterpriseId}/files/${item.sVendorFileTypeId}`,
      });
    },

    handleResize() {
      this.screenHeight = window.innerHeight - 260;
      this.isMobileOrIsTablet = window.innerWidth < 960;

      this.isFixed = window.innerWidth > 959;
      this.isMobile = window.innerWidth < 600;
      this.isCenter = window.innerWidth > 600 && window.innerWidth < 960;
      this.isTablet = window.innerWidth > 959 && window.innerWidth < 1264;

      if (window.innerWidth > 900) {
        this.screenWidth = window.innerWidth - 115;
      } else {
        this.screenWidth = window.innerWidth - 10;
      }

      // size to range
      // if (window.innerWidth < 599) {
      //   this.sizeRange = 25
      // }else if( window.innerWidth > 600 && window.innerWidth < 959){
      //   this.sizeRange = 25
      // }
      // else if( window.innerWidth > 960 && window.innerWidth < 1263 ){
      //   this.sizeRange = 25
      // }
      // else if(window.innerWidth > 1264 && window.innerWidth < 1903){
      //   this.sizeRange = 25
      // }
      // else if(window.innerWidth > 1904 ){
      //   this.sizeRange = 25
      // }
      // else{
      // }
    },

    // muestra el perfil del proveedor
    vendorDetail() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            // iPageNumber: 1,
          },
        }
      )
        .then((response) => {
          this.vendorDetailArr = response.data.results;
          this.sLogoKey = response.data.results.oVendorLogo.sLogoKey;
          this.vendorName = this.vendorDetailArr.sName;
          this.bVerified = this.vendorDetailArr.bVerified;
          this.bBlockStatus = this.vendorDetailArr.bBlockStatus;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // muestra los articulos que tiene el proveedor
    articleDetail() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          aImagesSizes: "md",
          sVendorEnterpriseId: this.$route.params.id,
          iImageLength: 1,
          sSearch: this.sSearch,
          sCategoryId: this.sCategoryId,
          sSubcategoryId: this.sSubCategoryId,
          sPriceOrder:
            this.priceStatus == false ? "desc" : this.priceStatus ? "asc" : "",
          sAlphOrder:
            this.alphabeticallyStatus == false
              ? "desc"
              : this.alphabeticallyStatus
              ? "asc"
              : "",
          dRatingMin: this.rangeStatus,
        },
      })
        .then((response) => {
          var i = 0;
          // this.articleDetailArr = response.data.results;
          this.articleDetailArr = response.data.results.map((e) => {
            i++;
            return {
              ...e,
              // aImages: (e.aImages.length > 0)? 'https://cdn.vuetifyjs.com/images/cards/cooking.png':'https://cdn.vuetifyjs.com/images/cards/cooking.png'
              aImages:
                e.aImages.length > 0
                  ? e.aImages[0].oImages.md
                  : "https://cdn.vuetifyjs.com/images/cards/cooking.png",
            };
          });
          // this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // muestra los usuarios que tienen el proveedor
    userDetail() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/employees`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          this.tableDataUsers = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // muestra los almacenes que tiene el proveedor
    warehousesDetail() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/warehouses`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          this.wareHouseDetailArr = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // muestra los documentos que tienen el proveedor
    documentDetail() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/files`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          this.documentDetailArr = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },

    // descarga el archivo seleccionado
    downloadFile(sFileUrl) {
      window.open(sFileUrl, "_blank");
    },

    // muestra el detalle del usuario
    detailItemUser(item) {
      this.$store.commit("setdialogDetailsGlobalUser", {
        active: true,
        arr: item,
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/employees/${item.sVendorId}`,
        apiChangeStatus: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/employees/${item.sVendorId}`,
      });
    },
    // muestra el detalle del almacen
    detailsWarehouse(item) {
      this.$store.commit("setdialogGlobalWarehouse", {
        active: true,
        arr: item,
        apidetail: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/warehouses/${item.sVendorWarehouseId}`,
        apidocument: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/warehouses/${item.sVendorWarehouseId}/files`,
      });
    },
    // muestra dialog para cambiar la contrasena del proveedor
    dialogPasswordChange() {
      this.$store.commit("setPasswordChange", {
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/password`,
        active: true,
      });
    },
    // muestra dialog para verificar o restringir al proveedor
    toRestrictItemVendors(item) {
      this.$store.commit("setGlobalDialog", {
        active: true,
        title: this.texts.vendorDetails.profileDetail.toRestrictTexts.title,
        descipcion: !item.bVerified
          ? this.texts.vendorDetails.profileDetail.toRestrictTexts
              .descriptionVerified
          : this.texts.vendorDetails.profileDetail.toRestrictTexts.description,
        note: !item.bVerified
          ? this.texts.vendorDetails.profileDetail.toRestrictTexts.noteVerified
          : this.texts.vendorDetails.profileDetail.toRestrictTexts.note,
        chkLabel: !item.bVerified ? this.texts.vendorDetails.profileDetail.toRestrictTexts
          .chkLabelVerified : this.texts.vendorDetails.profileDetail.toRestrictTexts
          .chkLabel ,
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${
          item.sVendorEnterpriseId
        }/visible/${!item.bVerified}`,
        return: false,
        dobleConfirm: true,
      });
    },
    // muestra dialog para habilitar o bloquear al usuario
    changeEstatusItem(item) {
      this.$store.commit("setGlobalDialog", {
        active: true,
        title: this.texts.vendorDetails.profileDetail.changeStatusTexts.title,
        descipcion: !item.bBlockStatus
          ? this.texts.vendorDetails.profileDetail.changeStatusTexts
              .descriptionUnlock
          : this.texts.vendorDetails.profileDetail.changeStatusTexts
              .description,
        note: !item.bBlockStatus
          ? this.texts.vendorDetails.profileDetail.changeStatusTexts.noteUnlock
          : this.texts.vendorDetails.profileDetail.changeStatusTexts.note,
        chkLabel: !item.bBlockStatus
          ? this.texts.vendorDetails.profileDetail.changeStatusTexts.chkLabelUnlock
          : this.texts.vendorDetails.profileDetail.changeStatusTexts.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${item.sVendorEnterpriseId}/block/`,
        return: false,
        dobleConfirm: true,
        params: { bBlock: !item.bBlockStatus },
      });
    },

    // muestra las categorias disponibles para el filtro de los articulos
    getCatalogo() {
      this.aSubCategory = [];
      this.sSubCategoryId = "";

      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          // iPageNumber: this.iCurrentPage,
          // iItemsPerPage: this.iItemsPerPage,
          // sSearch: this.sSearch,
          // tStart: this.startDate,
          // tEnd: this.endDate,
        },
      })
        .then((response) => {
          this.aCategory = response.data.results;
          if (this.selectLanguage == "sp") {
            this.aCategory.unshift({ sName: "Todos", sCategoryId: "" });
            this.aSubCategory.unshift({
              sSubcategoryName: "Todos",
              sSubcategoryId: "",
            });
          } else {
            this.aCategory.unshift({ sName: "All", sCategoryId: "" });
            this.aSubCategory.unshift({
              sSubcategoryName: "All",
              sSubcategoryId: "",
            });
          }
          // this.aSubCategory = []
          // this.sSubCategoryId = ''
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // muestra las subcategorias disponibles para el filtro de los articulos
    getSubCatalogo() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/subcategories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          sCategoryId: this.sCategoryId,
        },
      })
        .then((response) => {
          this.aSubCategory = response.data.results;
          this.aSubCategory.unshift({
            sSubcategoryName: "Todos",
            sSubcategoryId: "",
          });
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // muestra el detalle del articulo seleccionado
    detailArticleItem(item) {
      this.$store.commit("setTab", this.tabs);
      this.$router.push("/admin/article-detail/" + item.sProductId);
    },
    // regresa a la pagina anterior
    toReturn() {
      this.$store.commit("setTab", 0);
      // this.$router.push("/admin/vendors");
      this.$router.go(-1);
    },

    resizeDivName: function(text) {
      var contentHeight = document.getElementsByClassName("text-article-name");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightName = max;
    },
    resizeDivLocation: function(text) {
      var contentHeight = document.getElementsByClassName(
        "text-article-address"
      );
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightLocation = max;
      return text;
    },
    resizeDivPrice: function() {
      var contentHeight = document.getElementsByClassName("text-article-price");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightPrice = max;
    },
    // convierte el numero en formato money
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    iTab() {
      return this.$store.state.iTab;
    },
    alphabeticallyStatus() {
      return this.$store.state.bAlphabeticallyStatus;
    },
    priceStatus() {
      return this.$store.state.bPriceStatus;
    },
    rangeStatus() {
      return this.$store.state.bRangeStatus;
    },
  },
  watch: {
    refreshTable: function() {
      this.vendorDetail();
      this.userDetail();
      this.warehousesDetail();
      this.documentDetail();
    },
    sSearch: function() {
      this.$store.commit("setSearch", this.sSearch);
      this.articleDetail();
    },
    sCategoryId: function() {
      this.$store.commit("setsCategoryId", this.sCategoryId);
      this.articleDetail();
    },
    sSubCategoryId: function() {
      this.$store.commit("setsSubCategoryId", this.sSubCategoryId);
      this.articleDetail();
    },
    alphabeticallyStatus: function() {
      this.iCurrentPage = 1;
      this.articleDetail();
    },
    priceStatus: function() {
      this.iCurrentPage = 1;
      this.articleDetail();
    },
    rangeStatus: function() {
      this.iCurrentPage = 1;
      this.articleDetail();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.vendorTexts[this.selectLanguage];
        this.vendorDetail();
        this.articleDetail();
        this.getCatalogo();
        this.userDetail();
        this.warehousesDetail();
        this.documentDetail();
      }
    },

    tabs: function() {
      this.sSearch = "";
      this.sCategoryId = "";
      this.sSubCategoryId = "";
      this.$store.commit("setSearch", "");
      this.$store.commit("setsCategoryId", "");
      this.$store.commit("setsSubCategoryId", "");
      switch (this.tabs) {
        case 0:
          // this.vendorDetail();
          break;
        case 1:
          break;
        case 2:
          this.articleDetail();
          this.getCatalogo();
          break;
        case 3:
          this.userDetail();
          break;
        case 4:
          this.warehousesDetail();
          break;
        case 5:
          this.documentDetail();
          break;

        default:
          break;
      }
    },
  },
};
